/* eslint-disable jsx-a11y/img-redundant-alt */
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotifyImage } from '../../../icons/common/notify.svg';
import Notify from '../Notify';

export const ResetSuccess = () => {
  const { t } = useTranslation();

  return (
    <Notify
      title={t('access-recovery-form.success.title')}
      image={<NotifyImage />}
      text={[t('access-recovery-form.success.description')]}
    />
  );
};
