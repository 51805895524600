import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export enum Cabinet {
  Webmaster = 'webmaster',
  Advert = 'advert',
  DSP = 'dsp',
}

const useCabinet = () => {
  const [cabinet, setCabinet] = useState<Cabinet>(Cabinet.Webmaster);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('webmaster')) setCabinet(Cabinet.Webmaster);
    if (pathname.includes('advert')) setCabinet(Cabinet.Advert);
    if (pathname.includes('dsp')) setCabinet(Cabinet.DSP);
  }, [pathname]);

  return {
    cabinet,
    setCabinet,
  };
};

export default useCabinet;
