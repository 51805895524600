import { Trans, useTranslation } from 'react-i18next';
import useCabinet from '../../../hooks/useCabinet';
import Auth from '../../molecules/Auth';
import CabinetSelect from '../../atoms/CabinetSelect';
import SignUpFormColumn from './SignUpFormColumn';
import { useRoutes } from '../../../hooks/useRoutes';
import Link from '../../atoms/Link';

export const SignUpTemplate = () => {
  const { cabinet } = useCabinet();
  const { t } = useTranslation();

  const {
    getLoginRoute,
    PRIVACY_AND_DATA_PROTECTION_POLICY,
    PUBLISHER_AGREEMENT,
    ADVERTISER_AGREEMENT,
    PRIVACY_PERSONAL_DATA,
  } = useRoutes();

  const privacyLink: Record<string, string> = {
    webmaster: PUBLISHER_AGREEMENT.href,
    advert: ADVERTISER_AGREEMENT.href,
  };

  return (
    <Auth
      title={t('auth-reg-form.sign-up')}
      navigate={
        <Trans
          i18nKey="auth-reg-form.link-log-in"
          components={[
            <Link mix={['auth__link']} to={getLoginRoute(cabinet)}></Link>,
          ]}
        ></Trans>
      }
      privacy={
        <Trans
          i18nKey="auth-reg-form.rules"
          components={[
            <a
              href={privacyLink[cabinet]}
              className="auth__link"
              target="_blank"
              rel="noreferrer"
            ></a>,
            <a
              href={PRIVACY_AND_DATA_PROTECTION_POLICY.href}
              className="auth__link"
              target="_blank"
              rel="noreferrer"
            ></a>,
            <a
              href={PRIVACY_PERSONAL_DATA.href}
              className="auth__link"
              target="_blank"
              rel="noreferrer"
            ></a>,
          ]}
        ></Trans>
      }
    >
      <CabinetSelect />
      <SignUpFormColumn />
    </Auth>
  );
};
