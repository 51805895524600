export interface User {
  email: string;
  password: string;
}

export enum Cabinet {
  Webmaster = 'webmaster',
  Advert = 'advert',
  DSP = 'dsp',
  exchange = 'exchange',
}

const apiHost = 'https://stage-cabinet.24smi.info';

export const signIn = async (cabinet: Cabinet, user: User): Promise<any> => {
  return await fetch(`${apiHost}/${cabinet}/v1/user/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
};

export const signUp = async (
  cabinet: Cabinet,
  email: string,
  contacts: string,
): Promise<any> => {
  return await fetch(`${apiHost}/partner/register/email/${cabinet}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      contacts,
    }),
  });
};

export const resetPassword = async (
  email: string,
  cabinet: Cabinet,
  uid?: string,
  token?: string,
  newPassword?: { [key: string]: string },
): Promise<any> => {
  if (uid && token && newPassword) {
    return await fetch(`${apiHost}/partner/password/reset/${uid}/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newPassword),
    });
  }

  return await fetch(`stage/partner/password/reset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, cabinet }),
  });
};
