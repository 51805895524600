import 'normalize.css';
import 'swiper/css';
import '../shared/lib/styles/global.scss';
import { RouterProvider } from 'react-router-dom';
import { router } from '../pages/router';
import { ChatPolicy, CookieNotification } from '../widgets/Info';

export const App = () => {
  return (
    <>
      <CookieNotification />
      <ChatPolicy />
      <RouterProvider router={router} />
    </>
  );
};
