import Notify from '../Notify';
import { ReactComponent as NotifyImage } from '../../../icons/common/notify.svg';
import { useTranslation } from 'react-i18next';

export const SignUpSuccess = () => {
  const { t } = useTranslation();

  return (
    <Notify
      title={t('auth-reg-form.success.title')}
      image={<NotifyImage />}
      text={[t('auth-reg-form.success.description')]}
    />
  );
};
