import { FC } from 'react';
import { NoStrictEntityMods } from '@bem-react/classname';
import { bemClassName } from '../../../utils/bemClassName';

type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

type TitleLevel = 'hero' | 2 | 3 | 4 | 5;

interface ITitle {
  tag: TitleTag;
  level: TitleLevel;
  mods?: NoStrictEntityMods;
  mix?: string[];
  children?: React.ReactNode;
}

const title = bemClassName('title');

const Title: FC<ITitle> = ({ tag, level, children, mods = {}, mix = [] }) => {
  const Tag: TitleTag = tag;

  return <Tag className={title({ level, ...mods }, mix)}>{children}</Tag>;
};

export default Title;
