import { FC } from "react";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";

import { Button } from "../../atoms";
import S from "./Hero.module.scss";
import { HeroProps } from "./types";

export const Hero: FC<HeroProps> = ({ block, title, description, action, img, imgMobile, href = "" }) => {
  const isMobile = useMedia("only screen and (max-width: 743px)", false);

  return (
    <section className={S.Hero}>
      <h3 className={S.BlockTitle}>{block}</h3>
      <h1 className={S.Title}>{title}</h1>
      <p className={S.Description}>{description}</p>
      <div className={S.Action}>
        {isMobile ? (
          <Link to={href} style={{ color: "inherit" }}>
            <Button size="large" stretch>
              {action}
            </Button>
          </Link>
        ) : (
          <Link to={href}>
            <Button size="large">{action}</Button>
          </Link>
        )}
      </div>
      {isMobile && imgMobile ? (
        <img className={S.DecorImg} src={imgMobile} alt="decor img" />
      ) : (
        <img className={S.DecorImg} src={img} alt="decor img" />
      )}
    </section>
  );
};
