/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable no-useless-escape */
export class ReferralService {
  static init() {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('utm_content')) {
      const id = searchParams.get('utm_content');

      ReferralService.setOwnerId(id, {
        'max-age': 3600 * 24 * 30,
      });
    }
  }

  static getOwnerID() {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          'utm_content'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)',
      ),
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static setOwnerId(value: any, options: any) {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent('utm_content') + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }
}
