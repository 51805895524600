import getParameterByName from './getParameterByName';

export default class UTM {
  private readonly _utmName: string;

  private _keys: string[];

  constructor() {
    this._utmName = '_adwile_utm';
    this._keys = [
      'utm_source',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'utm_medium',
    ];
  }

  getUTM() {
    return JSON.parse(<string>localStorage.getItem(this._utmName)) || {};
  }

  setUTM() {
    return localStorage.setItem(
      this._utmName,
      JSON.stringify(
        Object.assign(this.getUTM(), this.getUTMFromQueryString()),
      ),
    );
  }

  getUTMFromQueryString() {
    const utm: { [key: string]: string } = {};

    this._keys.forEach((key) => {
      if (getParameterByName(key)) {
        utm[key] = getParameterByName(key) || '';
      }
    });

    return utm;
  }
}
