import { useTranslation } from 'react-i18next';
import useCabinet from '../../../hooks/useCabinet';
import { useRoutes } from '../../../hooks/useRoutes';
import Auth from '../../molecules/Auth';
import Link from '../../atoms/Link';
import NewPasswordForm from './NewPasswordForm';
import styles from './NewPasswordTemplate.module.scss';
import cs from 'classnames';

export const NewPassword = () => {
  const { cabinet } = useCabinet();
  const { t } = useTranslation();
  const { getLoginRoute } = useRoutes();
  return (
    <div className={cs(styles['wrapper'])}>
      <Auth
        title={t('set-new-password-form.title')}
        navigate={
          <Link to={getLoginRoute(cabinet)} mix={['auth__link']}>
            {t('set-new-password-form.link-back-tp-auth')}
          </Link>
        }
      >
        <NewPasswordForm />
      </Auth>
    </div>
  );
};
