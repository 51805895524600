import { useEffect } from 'react';
import { Cabinet } from '../../shared/lib/hooks/useCabinet';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

type Param = {
  uid: string | undefined;
  token: string | undefined;
  cabinet: Cabinet | undefined;
};

const UserPasswordResetPage = () => {
  const navigate = useNavigate();
  const { uid, token, cabinet } = useParams<Param>();

  useEffect(() => {
    if (!uid || !token || !cabinet) return;

    const params = { uid, token, cabinet };

    navigate({
      pathname: '/new-password',
      search: `?${createSearchParams(params)}`,
    });
  }, [uid, token, cabinet]);

  return null;
};

export default UserPasswordResetPage;
