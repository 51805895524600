import { PropsWithChildren } from 'react';
import styles from './inlie-message.module.scss';
import cs from 'classnames';

type InlineMessageProps = PropsWithChildren<{
  appearance?: 'default' | 'error';
  className?: string;
}>;

export const InlineMessage = ({
  children,
  appearance = 'default',
  className,
}: InlineMessageProps) => {
  console.log(className);

  return (
    <div className={cs(styles['wrapper'], styles[appearance], className)}>
      {children}
    </div>
  );
};
