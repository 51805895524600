import { useEffect, useState } from 'react';
import styles from './chat-policy.module.scss';
import cs from 'classnames';
import { ChatPolicyModal } from './chat-policy-modal/chat-policy-modal';

export const ChatPolicy = (): JSX.Element => {
  const [isChatLocked, setChatLocked] = useState(false);
  const [isPolicyVisible, setPolicyVisible] = useState(false);

  const chatId = '#hubspot-messages-iframe-container';

  useEffect(() => {
    const isChatPolicyAccepted = localStorage.getItem('chatPolicyAccepted');

    if (isChatPolicyAccepted) {
      return;
    }

    let chat: HTMLElement | null;
    chat = document.querySelector(chatId);

    if (!chat) {
      const interval = setInterval(() => {
        chat = document.querySelector(chatId);

        if (chat) {
          chat.style.zIndex = '500000';
          setChatLocked(true);
          clearInterval(interval);
        }
      }, 1000);
    }
  }, []);

  const onAcceptPolicyHandler = () => {
    setChatLocked(false);
    setPolicyVisible(false);
    localStorage.setItem('chatPolicyAccepted', 'true');
  };

  const onNotAcceptPolicyHandler = () => {
    setPolicyVisible(false);
  };

  return (
    <>
      <div
        onClick={() => setPolicyVisible(true)}
        className={cs(styles['chat-locker'], {
          [styles['locked']]: isChatLocked,
        })}
      />
      {isPolicyVisible && (
        <ChatPolicyModal
          onAcceptPolicy={onAcceptPolicyHandler}
          onNotAcceptPolicy={onNotAcceptPolicyHandler}
        />
      )}
    </>
  );
};
