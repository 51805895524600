import classnames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

import S from "./FadeInContainer.module.scss";
import { FadeInContainerProps } from "./types";

export const FadeInContainer: FC<FadeInContainerProps> = ({ children, flex }) => {
  const [isAnimated, setIsAnimated] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const intersection = useIntersection(containerRef, {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.1, 0.25, 0.3, 0.4, 0.5, 0.6, 0.75, 0.8, 0.9, 1],
  });

  const isInDisplay = (intersection?.intersectionRatio || 0) > 0.3;

  useEffect(() => {
    if (isInDisplay) {
      setIsAnimated(true);
    }
  }, [isInDisplay]);

  return (
    <div
      className={classnames(S.FadeInContainer, {
        [S.Visible]: isInDisplay || isAnimated,
        [S.Flex]: flex,
      })}
      ref={containerRef}
    >
      {children}
    </div>
  );
};
