import { FC } from 'react';
import { ReactComponent as TelegramIcon } from '../../../shared/lib/icons/logo/telegram.svg';
import { ReactComponent as VkIcon } from '../../../shared/lib/icons/logo/vk.svg';
import { BlockHeader, Container } from '../../../shared/lib/ui';
import S from './Info.module.scss';
import useCabinet from '../../../shared/lib/hooks/useCabinet';
import {
  TG_ADWILE_PUBLISHER_LINK,
  TG_ADWILE_SUPPORT,
  VK_ADWILE_LINK,
} from '../../../shared/lib/constants/links';
import { useTranslation } from 'react-i18next';

export const Info: FC = () => {
  const { t } = useTranslation();
  const { cabinet } = useCabinet();

  return (
    <div className={S.Footer}>
      <Container>
        <BlockHeader
          whiteText
          block={t('info.title')}
          title={
            <div className={S.WrapperText}>
              {t('info.p1')}
              <a href={LINKS[cabinet].supportLink} className={S.LinkFooter}>
                {LINKS[cabinet].supportText}
              </a>
              {t('info.p2')}
              <a href={LINKS[cabinet].tgLink} className={S.LinkFooter}>
                {LINKS[cabinet].tgText}
              </a>
            </div>
          }
          noTopMargin
        />
        <div className={S.WrapperSocial}>
          <a
            className={S.icon}
            target="_blank"
            href={LINKS[cabinet].tgLink}
            rel="noreferrer"
          >
            <TelegramIcon />
          </a>
          <a
            className={S.icon}
            target="_blank"
            href={VK_ADWILE_LINK}
            rel="noreferrer"
          >
            <VkIcon />
          </a>
        </div>
      </Container>
    </div>
  );
};

const LINKS = {
  webmaster: {
    supportLink: 'mailto:publisher@adwile.com',
    supportText: 'publisher@adwile.com',
    tgLink: TG_ADWILE_PUBLISHER_LINK,
    tgText: 't.me/adwile_publisher',
  },
  advert: {
    supportLink: 'mailto:support@adwile.com',
    supportText: 'support@adwile.com',
    tgLink: TG_ADWILE_SUPPORT,
    tgText: 't.me/adwile_support',
  },
  dsp: {
    supportLink: 'mailto:support@adwile.com',
    supportText: 'support@adwile.com',
    tgLink: TG_ADWILE_SUPPORT,
    tgText: 't.me/adwile_support',
  },
};
