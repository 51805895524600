import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { bemClassName } from '../../../utils/bemClassName';
import { Link, useLocation } from 'react-router-dom';
import useCabinet, { Cabinet } from '../../../hooks/useCabinet';

const cabinetSelect = bemClassName('cabinet-select');

const CabinetSelect: FC = () => {
  const { pathname } = useLocation();
  const { cabinet: selectedCabinet } = useCabinet();
  const { t } = useTranslation();

  const pathArr = pathname.split('/');

  const CABINETS = [
    {
      cabinet: Cabinet.Webmaster,
      title: t('auth-reg-form.publishers'),
      to: `${getPath(pathArr)}/webmaster`,
    },
    {
      cabinet: Cabinet.Advert,
      title: t('auth-reg-form.advertisers'),
      to: `${getPath(pathArr)}/advert`,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getFormLabel = (pathname: string) => {
    if (pathname.includes('login')) {
      return t('auth-reg-form.log-in-as');
    }
    if (pathname.includes('sign-up')) {
      return t('auth-reg-form.cabinet-for');
    }
  };

  return (
    <div className={cabinetSelect()}>
      <div className={cabinetSelect('label')}>{getFormLabel(pathname)}</div>
      <div className={cabinetSelect('list')}>
        {CABINETS.map((cabinet, key) => {
          return (
            <Link
              to={cabinet.to}
              // eslint-disable-next-line react/no-array-index-key
              key={key}
              className={cabinetSelect('item', {
                active: cabinet.cabinet === selectedCabinet,
              })}
            >
              {cabinet.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CabinetSelect;

const getPath = (pathArr: string[]) => {
  let path = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < pathArr.length - 1; i++) {
    path += `/${pathArr[i]}`;
  }
  return path;
};
