import UTM from '../utils/utm';
import { ReferralService } from './referral.service';

export interface User {
  email: string;
  password: string;
}

export enum Cabinet {
  Webmaster = 'webmaster',
  Advert = 'advert',
  DSP = 'dsp',
}

export class AuthService {
  private readonly _apiHost = process.env.REACT_APP_API_HOST;

  async signIn(cabinet: Cabinet, user: User): Promise<any> {
    return await fetch(`${this._apiHost}/${cabinet}/v1/user/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
  }

  async signUp(
    cabinet: Cabinet,
    email: string,
    contacts: string,
  ): Promise<any> {
    const referralOwnerID = ReferralService.getOwnerID();

    return await fetch(`${this._apiHost}/partner/register/email/${cabinet}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        contacts,
        ref_owner_id: referralOwnerID,
        ...new UTM().getUTM(),
      }),
    });
  }

  async reset(email: string, cabinet: Cabinet): Promise<any> {
    return await fetch(`${this._apiHost}/partner/password/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, cabinet }),
    });
  }

  async newPassword(
    uid: string,
    token: string,
    newPassword: { [key: string]: string },
  ): Promise<any> {
    return await fetch(
      `${this._apiHost}/partner/password/reset/${uid}/${token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPassword),
      },
    );
  }
}
