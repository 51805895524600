import { Trans, useTranslation } from 'react-i18next';
import useCabinet from '../../../hooks/useCabinet';
import Auth from '../../molecules/Auth';
import CabinetSelect from '../../atoms/CabinetSelect';
import SignInForm from './SignInForm';
import Link from '../../atoms/Link';

export const SignInTemplate = () => {
  const { cabinet } = useCabinet();
  const { t } = useTranslation();

  return (
    <Auth
      title={t('auth-reg-form.log-in')}
      navigate={
        <Trans
          i18nKey="auth-reg-form.has-no-account"
          components={[
            <Link to={`/sign-up/${cabinet}`} mix={['auth__link']}></Link>,
          ]}
        ></Trans>
      }
    >
      <CabinetSelect />
      <SignInForm />
    </Auth>
  );
};
