import { createBrowserRouter } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import UserPasswordResetPage from './UserPassword/UserPassword';
import { FallbackHelper, StageUniversalForm } from '../shared/lib/ui/organisms';

const AdvertPage = lazy(() =>
  import('./Advert').then((module) => ({
    default: module.AdvertPage,
  })),
);

const MainPage = lazy(() =>
  import('./Main/Main').then((module) => ({
    default: module.MainPage,
  })),
);

const PublishersPage = lazy(() =>
  import('./Webmaster').then((module) => ({
    default: module.PublishersPage,
  })),
);

const FormPage = lazy(() =>
  import('./FromPage/FormPage').then((module) => ({
    default: module.FormPage,
  })),
);

const SignInTemplate = lazy(() =>
  import('../shared/lib/ui/organisms').then((module) => ({
    default: module.SignInTemplate,
  })),
);

const SignUpTemplate = lazy(() =>
  import('../shared/lib/ui/organisms').then((module) => ({
    default: module.SignUpTemplate,
  })),
);

const ResetTemplate = lazy(() =>
  import('../shared/lib/ui/organisms/ResetTemplate/ResetTemplate').then(
    (module) => ({
      default: module.ResetTemplate,
    }),
  ),
);

const ResetSuccess = lazy(() =>
  import('../shared/lib/ui/organisms').then((module) => ({
    default: module.ResetSuccess,
  })),
);

const NewPassword = lazy(() =>
  import('../shared/lib/ui/organisms').then((module) => ({
    default: module.NewPassword,
  })),
);

const SetNewPasswordSuccess = lazy(() =>
  import('../shared/lib/ui/organisms').then((module) => ({
    default: module.SetNewPasswordSuccess,
  })),
);

const SignUpSuccess = lazy(() =>
  import('../shared/lib/ui/organisms').then((module) => ({
    default: module.SignUpSuccess,
  })),
);

const CallTemplate = lazy(() =>
  import('../shared/lib/ui/organisms/CallTemplate/CallTemplate').then(
    (module) => ({
      default: module.default,
    }),
  ),
);

const MessageTemplate = lazy(() =>
  import('../shared/lib/ui/organisms/MessageTemplate/MessageTemplate').then(
    (module) => ({
      default: module.default,
    }),
  ),
);

const NotFound = lazy(() =>
  import('./NotFound/NotFound').then((module) => ({
    default: module.NotFound,
  })),
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<FallbackHelper />}>
        <MainPage />
      </Suspense>
    ),
    children: [
      {
        path: '/advert',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <AdvertPage />
          </Suspense>
        ),
      },
      {
        path: '/webmaster',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <PublishersPage />
          </Suspense>
        ),
      },
      {
        path: '/sign-up',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <FormPage />
          </Suspense>
        ),
        children: [
          {
            path: '/sign-up/advert',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignUpTemplate />
              </Suspense>
            ),
          },
          {
            path: '/sign-up/webmaster',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignUpTemplate />
              </Suspense>
            ),
          },
          {
            path: '/sign-up/success/advert',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignUpSuccess />
              </Suspense>
            ),
          },
          {
            path: '/sign-up/success/webmaster',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignUpSuccess />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/user/login',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <FormPage />
          </Suspense>
        ),
        children: [
          {
            path: '/user/login/advert',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignInTemplate />
              </Suspense>
            ),
          },
          {
            path: '/user/login/webmaster',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignInTemplate />
              </Suspense>
            ),
          },
          {
            path: '/user/login/dsp',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <SignInTemplate />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/reset',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <FormPage />
          </Suspense>
        ),
        children: [
          {
            path: '/reset/advert',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <ResetTemplate />
              </Suspense>
            ),
          },
          {
            path: '/reset/webmaster',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <ResetTemplate />
              </Suspense>
            ),
          },
          {
            path: '/reset/success/webmaster',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <ResetSuccess />
              </Suspense>
            ),
          },
          {
            path: '/reset/success/advert',
            element: (
              <Suspense fallback={<FallbackHelper />}>
                <ResetSuccess />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/new-password',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <NewPassword />
          </Suspense>
        ),
      },
      {
        path: '/call',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <CallTemplate />
          </Suspense>
        ),
      },
      {
        path: '/message',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <MessageTemplate />
          </Suspense>
        ),
      },
      {
        path: '/message',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <MessageTemplate />
          </Suspense>
        ),
      },
      {
        path: '/new-password/success/advert',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <SetNewPasswordSuccess />
          </Suspense>
        ),
      },
      {
        path: '/new-password/success/webmaster',
        element: (
          <Suspense fallback={<FallbackHelper />}>
            <SetNewPasswordSuccess />
          </Suspense>
        ),
      },
      {
        path: '/user/password/reset/:uid/:token/:cabinet',
        element: <UserPasswordResetPage />,
      },
      {
        path: '/stage/form/:cabinet?',
        element: <StageUniversalForm />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<FallbackHelper />}>
        <NotFound />
      </Suspense>
    ),
  },
]);
