import { FC, FormEvent, useState } from 'react';
import { AuthService } from '../../../../services/auth.service';
import { bemClassName } from '../../../../utils/bemClassName';
import useCabinet from '../../../../hooks/useCabinet';
import { useTranslation } from 'react-i18next';
import TextField from '../../../atoms/TextField';
import { Button } from '../../../atoms';
import { useNavigate } from 'react-router-dom';

const resetForm = bemClassName('reset-form');

const ResetForm: FC = () => {
  const authService: AuthService = new AuthService();
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const navigate = useNavigate();
  const { cabinet } = useCabinet();
  const { t } = useTranslation();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    authService
      .reset(email, cabinet)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          navigate(`/reset/success/${cabinet}`);
        }

        return res.json();
      })
      .then((data) => {
        if (data.email) {
          setErrors((prevState) => {
            return {
              ...prevState,
              email: data.email,
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <form className={resetForm()} onSubmit={onSubmit}>
      <TextField
        value={email}
        onChange={setEmail}
        label={t('access-recovery-form.email')}
        placeholder={t('access-recovery-form.email-placeholder')}
        mix={[resetForm('text-field')]}
        name="email"
        error={(errors.email && errors.email[0]) || ''}
      />
      <Button formBtn type="submit">
        {t('access-recovery-form.btn-recovery')}
      </Button>
    </form>
  );
};

export default ResetForm;
