import { FC, FormEvent, useEffect, useState } from 'react';
import { AuthService, Cabinet } from '../../../../services/auth.service';

import { useTranslation } from 'react-i18next';
import useCabinet from '../../../../hooks/useCabinet';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '../../../atoms/TextField';
import { Button } from '../../../atoms';
import { NoStrictEntityMods } from '@bem-react/classname';
import { bemClassName } from '../../../../utils/bemClassName';
import ReCAPTCHA from 'react-google-recaptcha';

type SignUpFormSize = 'large' | 'medium';

type SignUpFormDuration = 'row' | 'column';

interface ISignUpForm {
  mods?: NoStrictEntityMods;
  mix?: string[];
  size?: SignUpFormSize;
  duration?: SignUpFormDuration;
}

const signUpForm = bemClassName('sign-up-form');

const MAX_ERROR_COUNT = 2;

const SignUpFormColumn: FC<ISignUpForm> = ({
  size = 'medium',
  duration = 'column',
  mods = {},
  mix = [],
}) => {
  const navigate = useNavigate();
  const authService: AuthService = new AuthService();
  const [email, setEmail] = useState<string>('');
  const [contacts, setContacts] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const { cabinet } = useCabinet();
  const { t } = useTranslation();
  const [errorCount, setErrorCount] = useState(0);

  const isDisabled = errorCount > MAX_ERROR_COUNT;

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    authService
      .signUp(cabinet, email, contacts)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          if (cabinet === Cabinet.Webmaster) {
            // @ts-ignore
            window.ym(62808988, 'reachGoal', 'pub_lead');
          } else {
            // @ts-ignore
            window.ym(62808988, 'reachGoal', 'adv_lead');
          }

          navigate(`/sign-up/success/${cabinet}`);
        }

        return res.json();
      })
      .then((data) => {
        if (data.email || data.contacts) {
          setErrors((prevState) => {
            return {
              ...prevState,
              email: data.email || '',
              contacts: data.contacts || '',
            };
          });
        }

        setErrorCount((count) => count + 1);
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      className={signUpForm({ duration, ...mods }, mix)}
      onSubmit={onSubmit}
    >
      <TextField
        label={t('auth-reg-form.email')}
        value={email}
        onChange={setEmail}
        size={size}
        placeholder={t('auth-reg-form.email-placeholder')}
        mix={[signUpForm('text-field')]}
        name="email"
        error={(errors.email && errors.email[0]) || ''}
      />
      <TextField
        label={t('auth-reg-form.contacts')}
        value={contacts}
        onChange={setContacts}
        size={size}
        placeholder={t('auth-reg-form.contacts-placeholder')}
        mix={[signUpForm('text-field')]}
        name="contacts"
        error={(errors.contacts && errors.contacts[0]) || ''}
      />
      {isDisabled && (
        <ReCAPTCHA
          sitekey="6Lea-dInAAAAALyhLR6DupJ3iat6pd1ZkipkINtm"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 24,
          }}
          onChange={() => setErrorCount(0)}
        />
      )}
      <Button formBtn type="submit" disabled={isDisabled}>
        {t('auth-reg-form.btn-get-access')}
      </Button>
    </form>
  );
};

export default SignUpFormColumn;
