import styles from './chat-policy-modal.module.scss';
import cs from 'classnames';
import { useRoutes } from '../../../../../shared/lib/hooks/useRoutes';
import { Trans, useTranslation } from 'react-i18next';

type ChatPolicyProps = {
  onAcceptPolicy: () => void;
  onNotAcceptPolicy: () => void;
};

export const ChatPolicyModal = ({
  onAcceptPolicy,
  onNotAcceptPolicy,
}: ChatPolicyProps): JSX.Element => {
  const { t } = useTranslation();

  const { PRIVACY_AND_DATA_PROTECTION_POLICY, PRIVACY_PERSONAL_DATA } =
    useRoutes();

  return (
    <div onClick={onNotAcceptPolicy} className={cs(styles['modal-wrapper'])}>
      <div className={cs(styles['modal'])}>
        <div className={cs(styles['header'])}>
          <div className={cs(styles['title'])}>{t('chat_policy.title')}</div>
          <button
            onClick={onNotAcceptPolicy}
            type="button"
            className={cs(styles['close'])}
          >
            &#10006;
          </button>
        </div>
        <div className={cs(styles['text'])}>
          <Trans
            i18nKey="chat_policy.description"
            components={[
              <a
                className={cs(styles['link'])}
                href={PRIVACY_AND_DATA_PROTECTION_POLICY.href}
              ></a>,
              <a
                className={cs(styles['link'])}
                href={PRIVACY_PERSONAL_DATA.href}
              ></a>,
            ]}
          />
        </div>
        <div className={cs(styles['buttons'])}>
          <button
            className={cs(styles['button'])}
            type="button"
            onClick={onAcceptPolicy}
          >
            {t('chat_policy.button')}
          </button>
        </div>
      </div>
    </div>
  );
};
