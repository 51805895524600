import { useTranslation } from 'react-i18next';
import { Cabinet } from './useCabinet';
import {
  ADWILE_DOCS_PAGE_LINK,
  BLOG_ADWILE_LINK,
  HELP_ADWILE_LINK,
  PREMIUM_ADWILE_LINK,
} from '../constants/links';

export const useRoutes = () => {
  const { t } = useTranslation('common');

  const WEBMASTER_ROUTE = {
    href: '/webmaster',
    title: t('header.link-publisher'),
  };

  const ADVERT_ROUTE = {
    href: '/advert',
    title: t('header.link-advertisers'),
  };

  const BRAND_ROUTE = {
    href: PREMIUM_ADWILE_LINK,
    title: t('header.link-agencies'),
    target: '_blank',
    rel: 'noreferrer',
  };

  const KNOWLEDGE_BASE_ROUTE = {
    href: HELP_ADWILE_LINK,
    title: 'База знаний',
  };

  const BLOG_ROUTE = {
    href: BLOG_ADWILE_LINK,
    title: 'Блог',
  };

  const ADVERTISER_AGREEMENT = {
    href: `${ADWILE_DOCS_PAGE_LINK}/polzovatelskoye_reklamodatel.pdf`,
  };

  const PUBLISHER_AGREEMENT = {
    href: `${ADWILE_DOCS_PAGE_LINK}/polzovatelskoye_publisher.pdf`,
  };

  const COOKIES_POLICY = {
    href: `${ADWILE_DOCS_PAGE_LINK}/politika_obrabotki_faylov_cookie.pdf`,
  };

  const PRIVACY_AND_DATA_PROTECTION_POLICY = {
    href: `${ADWILE_DOCS_PAGE_LINK}/politika_konfidentsialnosti_i_zashchity_dannykh.pdf`,
  };

  const PRIVACY_PERSONAL_DATA = {
    href: `${ADWILE_DOCS_PAGE_LINK}/soglasiye_na_obrabotku_personalnykh_dannykh.pdf`,
  };

  const getLoginRoute = (cabinet: Cabinet) => {
    return `/user/login/${cabinet}`;
  };

  return {
    getLoginRoute,
    COOKIES_POLICY,
    PUBLISHER_AGREEMENT,
    PRIVACY_AND_DATA_PROTECTION_POLICY,
    PRIVACY_PERSONAL_DATA,
    ADVERTISER_AGREEMENT,
    BLOG_ROUTE,
    KNOWLEDGE_BASE_ROUTE,
    BRAND_ROUTE,
    ADVERT_ROUTE,
    WEBMASTER_ROUTE,
  };
};
