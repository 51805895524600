/* eslint-disable no-redeclare */
import { useEffect, useRef, useState } from 'react';
import styles from './language.module.scss';
import cs from 'classnames';

export type Language = {
  language: string;
  name: string;
  flag: string;
};

export type LanguageProps = {
  languages: Language[];
  currentLanguage: string;
  onClick: (language: string) => void;
};

export const Language = ({
  languages,
  onClick,
  currentLanguage,
}: LanguageProps) => {
  const [open, setOpen] = useState(false);
  const languageSelector = useRef<HTMLDivElement | null>(null);
  const element = languageSelector.current;

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClose);
    }
    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, [open]);

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleClose = (e: any) => {
    let isClickOutside = true;
    const elementClass = e.target?.getAttribute('class');

    if (element) {
      element.querySelector(`.${elementClass}`)
        ? (isClickOutside = false)
        : (isClickOutside = true);
    }

    if (isClickOutside) {
      setOpen(false);
    }
  };

  const handleChangeLanguage = (language: string) => {
    onClick(language);
    setOpen(false);
  };

  return (
    <div
      ref={languageSelector}
      id="language-selector"
      className={cs(styles['wrapper'])}
    >
      <div
        className={cs(styles['language-list-wrapper'], {
          [styles['open']]: open,
        })}
      >
        {languages.map(({ flag, name, language }) => (
          <div
            onClick={() => handleChangeLanguage(language)}
            key={name}
            className={cs(
              styles['current-language'],
              styles['current-language-item'],
            )}
          >
            <img className={cs(styles['flag'])} src={flag} alt="flag" />
            <div className={cs(styles['title'])}>{name}</div>
          </div>
        ))}
      </div>

      {languages
        .filter((it) => it.language === currentLanguage)
        .map(({ flag, name }) => (
          <div
            onClick={handleOpen}
            key={Date.now()}
            className={cs(styles['current-language'])}
          >
            <img className={cs(styles['flag'])} src={flag} alt="flag" />
            <div className={cs(styles['title'])}>{name}</div>
          </div>
        ))}
    </div>
  );
};
