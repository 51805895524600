import classnames from 'classnames';
import { FC } from 'react';
import S from './Button.module.scss';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  size = 'small',
  isActive,
  theme,
  stretch,
  formBtn,
  ...props
}) => (
  <button
    className={classnames(S.Button, {
      [S.Large]: size === 'large',
      [S.IsActive]: isActive,
      [S.GreyTheme]: theme === 'grey',
      [S.Stretch]: stretch,
      [S.DarkTheme]: theme === 'dark',
      [S.Tool]: size === 'tool',
      [S.formBtn]: formBtn,
      [S.disabled]: props.disabled,
    })}
    data-isactive={isActive}
    // eslint-disable-next-line react/button-has-type
    type={type || 'button'}
    {...props}
  >
    {children}
  </button>
);
