/* eslint-disable jsx-a11y/img-redundant-alt */
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotifyImage } from '../../../icons/common/notify.svg';
import Notify from '../Notify';
import styles from './SetNewPasswordSuccess.module.scss';
import cs from 'classnames';

export const SetNewPasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className={cs(styles['wrapper'])}>
      <Notify
        title={t('set-new-password-form.success.title')}
        image={<NotifyImage />}
        text={[t('set-new-password-form.success.description')]}
      />
    </div>
  );
};
