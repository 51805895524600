import classnames from 'classnames';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Button, FadeInContainer } from '../../atoms';
import S from './Block.module.scss';
import { BlockProps } from './types';

export const Block: FC<BlockProps> = ({
  color,
  title,
  descr,
  action,
  size = 'large',
  img,
  imagePadding,
  fullImg,
  href,
  actionMobileMargins,
  noImageBottomPadding,
  imgClass,
  leftWrapClass,
  actionClass,
  blockClass,
}) => {
  const isMobile = useMedia('only screen and (max-width: 743px)', false);

  const actionComponent = useMemo(() => {
    if (!action) {
      return null;
    }

    return (
      <Button size="small">
        {href ? (
          <Link to={href} style={{ color: 'inherit' }}>
            {action}
          </Link>
        ) : (
          action
        )}
      </Button>
    );
  }, [action, href]);

  return (
    <FadeInContainer flex>
      <div
        className={classnames(S.Block, {
          [S.BlockBgGreen]: color === 'green',
          [S.BlockBgBlack]: color === 'black',
          [S.BlockBgWhite]: color === 'white',
          [S.BlockBgOrange]: color === 'orange',
          [S.BlockBgBlue]: color === 'blue',
          [S.BlockBgViolet]: color === 'violet',
          [S.BlockBgBlueViolet]: color === 'blueViolet',
          [S.Small]: size === 'small',
          [S.NoPadding]: fullImg,
          [blockClass ?? '']: !!blockClass,
        })}
      >
        <div
          className={classnames(S.BlockLeftWrap, {
            [S.WithPadding]: fullImg,
            [leftWrapClass ?? '']: !!leftWrapClass,
          })}
        >
          <div className={S.BlockInfo}>
            <div>
              <h2 className={S.BlockTitle}>{title}</h2>
            </div>
            <p className={S.BlockDesc}>{descr}</p>
          </div>
          {!isMobile && <div className={S.BlockAction}>{actionComponent}</div>}
        </div>
        <div
          className={classnames(S.BlockRightWrap, {
            [S.Padding]: imagePadding && !fullImg,
            [S.NoImageBottomPadding]: noImageBottomPadding,
          })}
        >
          <img
            className={classnames(S.BlockRightImg, {
              [S.FullImg]: fullImg,
              [imgClass ?? '']: !!imgClass,
            })}
            src={img}
            alt=""
          />
        </div>
        {isMobile && (
          <div
            className={classnames(S.BlockAction, {
              [S.Margins]: !!actionMobileMargins,
              [actionClass ?? '']: !!actionClass,
            })}
          >
            {actionComponent}
          </div>
        )}
      </div>
    </FadeInContainer>
  );
};
