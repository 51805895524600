/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { FC, ReactNode } from 'react';
import Title from '../../atoms/Title';
import Text from '../../atoms/Text';
import { bemClassName } from '../../../utils/bemClassName';
import useCabinet from '../../../hooks/useCabinet';
import { useRoutes } from '../../../hooks/useRoutes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface INotify {
  title: string;
  image: ReactNode;
  text: string[];
}

const notify = bemClassName('notify');

const Notify: FC<INotify> = ({ title, text = [], image }) => {
  const { cabinet } = useCabinet();
  const { getLoginRoute } = useRoutes();
  const { t } = useTranslation();

  return (
    <section className={notify()}>
      <div className={notify('inner')}>
        <Title tag="h3" level={3} mix={[notify('title')]}>
          {title}
        </Title>
        <div className={notify('image')}>{image}</div>
        {text.map((t, key) => {
          return (
            <Text mix={[notify('text')]} key={key}>
              {t}
            </Text>
          );
        })}
        <Link className={notify('link')} to={getLoginRoute(cabinet)}>
          {t('auth-reg-form.success.btn-back-to-log-in')}
        </Link>
      </div>
    </section>
  );
};

export default Notify;
