import { FC, forwardRef, useState, Fragment } from 'react';
import { NoStrictEntityMods } from '@bem-react/classname';
import { bemClassName } from '../../../utils/bemClassName';

type TextFieldType = 'primary';

type TextFieldSize = 'large' | 'medium';

type TextFieldHTMLType = 'text' | 'password';

interface ITextField {
  value: string;
  onChange(value: string): void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: TextFieldType;
  size?: TextFieldSize;
  htmlType?: TextFieldHTMLType;
  mods?: NoStrictEntityMods;
  mix?: string[];
  name?: string;
  error?: string;
  as?: 'input' | 'textarea';
  rows?: number;
  tool?: React.ReactNode;
}

const textField = bemClassName('text-field');

const TextField = forwardRef<HTMLInputElement, ITextField>(
  (
    {
      value,
      onChange,
      label,
      placeholder,
      disabled = false,
      type = 'primary',
      size = 'medium',
      mods = {},
      mix = [],
      htmlType = 'text',
      name,
      error,
      as = 'input',
      rows = 6,
      tool,
    },
    ref,
  ) => {
    const [focused, setFocused] = useState<boolean>(false);

    const component = () => {
      switch (as) {
        case 'textarea':
          return (
            <textarea
              rows={rows}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
              placeholder={placeholder}
              className={textField('field')}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              name={name}
            />
          );
        default:
          return (
            <input
              ref={ref}
              type={htmlType}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
              placeholder={placeholder}
              className={textField('field')}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              name={name}
            />
          );
      }
    };

    const containerProps = {
      className: textField(
        { type, size, focused, multiline: as === 'textarea', ...mods },
        mix,
      ),
    };

    const inner = (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {label && <span className={textField('label')}>{label}</span>}
          {tool && <div className={textField('tool')}>{tool}</div>}
        </div>
        <div className={textField('field-group')}>{component()}</div>
        {error && <div className={textField('error')}>{error}</div>}
      </>
    );

    const HtmlTag = tool ? 'div' : 'label';
    return <HtmlTag {...containerProps}>{inner}</HtmlTag>;
  },
);

export default TextField;
