import { FC } from 'react';
import { NoStrictEntityMods } from '@bem-react/classname';
import { Link as RouterLink } from 'react-router-dom';
import { bemClassName } from '../../../utils/bemClassName';

export interface ILink {
  to: string;
  title?: string;
  mods?: NoStrictEntityMods;
  mix?: string[];
  target?: string;
  rel?: string;
  children?: React.ReactNode;
}

const link = bemClassName('link');

const Link: FC<ILink> = ({
  to,
  title,
  mods = {},
  mix = [],
  target,
  rel,
  children,
}) => {
  return (
    <RouterLink
      to={to}
      title={title}
      className={link(mods, mix)}
      target={target}
      rel={rel}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
