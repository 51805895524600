import { FC, ReactNode } from 'react';

import { bemClassName } from '../../../utils/bemClassName';
import Title from '../../atoms/Title';

interface IAuth {
  title: string;
  navigate?: ReactNode | string;
  privacy?: ReactNode | string;
  children?: React.ReactNode;
}

const auth = bemClassName('auth');

const Auth: FC<IAuth> = ({ title, navigate, privacy, children }) => {
  return (
    <section className={auth()}>
      <div className={auth('inner')}>
        <Title tag="h3" level={3} mix={[auth('title')]}>
          {title}
        </Title>
        {children}
        {navigate && <div className={auth('navigate')}>{navigate}</div>}
        {privacy && <div className={auth('privacy')}>{privacy}</div>}
      </div>
    </section>
  );
};

export default Auth;
