import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { ReactComponent as DesktopIcon } from '../../../icons/common/desktop.svg';
import { ReactComponent as DesktopActiveIcon } from '../../../icons/common/desktop-active.svg';
import { ReactComponent as MobileIcon } from '../../../icons/common/mobile.svg';
import { ReactComponent as MobileActiveIcon } from '../../../icons/common/mobile-active.svg';
import { Button, FadeInContainer } from '../../atoms';
import S from './SliderBlock.module.scss';
import { ActionProps, SliderBlockProps } from './types';

export const SliderBlock: FC<SliderBlockProps> = ({
  color,
  title,
  descr,
  mobileActions,
  desktopActions,
  onChangeId,
  lang,
  id,
}) => {
  const isMobile = useMedia('only screen and (max-width: 560px)', false);

  const [activeImage, setActiveImage] = useState<string>(mobileActions[0]?.img);
  const [device, setDevice] = useState<'mobile' | 'desktop'>('mobile');

  useEffect(() => {
    if (device === 'desktop') {
      setActiveImage(desktopActions[id - 1]?.img);
      return;
    }
    setActiveImage(mobileActions[id - 1]?.img);
  }, [lang, device]);

  return (
    <FadeInContainer>
      <div
        className={classnames(S.Block, {
          [S.BlockGreen]: color === 'green',
          [S.BlockBlack]: color === 'black',
        })}
      >
        <div className={S.BlockLeftWrap}>
          <div>
            <div>
              <h2 className={S.BlockTitle}>{title}</h2>
            </div>
            <p className={S.BlockDesc}>{descr}</p>
          </div>
          {/* кнопки */}
          <div className={S.ActionListWrapper}>
            {(device === 'mobile' ? mobileActions : desktopActions).map(
              (item: ActionProps) => (
                <Button
                  isActive={item.id === id}
                  key={item.id}
                  theme="grey"
                  onClick={() => {
                    setActiveImage?.(item.img);
                    onChangeId?.(item.id);
                  }}
                  size={isMobile ? 'small' : 'large'}
                >
                  {item.text}
                </Button>
              ),
            )}
          </div>
        </div>
        <div className={S.BlockRightWrap}>
          {device === 'mobile' ? (
            <div className={S.WrapperRightPhone}>
              <div className={S.PhoneWrapper}>
                <img
                  className={S.BackPhone}
                  src="/assets/img/main/phone.png"
                  alt=""
                />
                <img
                  className={S.PhoneImage}
                  src={activeImage}
                  alt="phone screen"
                />
              </div>
            </div>
          ) : (
            <div className={S.WrapperRightDesktop}>
              <img
                className={S.DesktopImage}
                src={activeImage}
                alt="desktop screen"
              />
            </div>
          )}
          <div className={S.ToggleBtnsWrapper}>
            <button
              type="button"
              className={classnames(S.DeviceWrap, {
                [S.ActiveDevice]: device === 'mobile',
              })}
              onClick={() => {
                setDevice?.('mobile');
                setActiveImage?.(mobileActions[0].img);
                onChangeId?.(mobileActions[0].id);
              }}
            >
              {device === 'mobile' ? <MobileActiveIcon /> : <MobileIcon />}
            </button>
            <button
              type="button"
              className={classnames(S.DeviceWrap, {
                [S.ActiveDevice]: device === 'desktop',
              })}
              onClick={() => {
                setDevice?.('desktop');
                setActiveImage?.(desktopActions[0].img);
                onChangeId?.(desktopActions[0].id);
              }}
            >
              {device === 'desktop' ? <DesktopActiveIcon /> : <DesktopIcon />}
            </button>
          </div>
        </div>
      </div>
    </FadeInContainer>
  );
};
