import { FC, FormEvent, useState } from 'react';
import { AuthService, Cabinet } from '../../../../services/auth.service';
import { bemClassName } from '../../../../utils/bemClassName';
import TextField from '../../../atoms/TextField';
import { Button } from '../../../atoms';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

type QueryParams = {
  uid: string;
  token: string;
  cabinet: Cabinet;
};

const newPasswordForm = bemClassName('new-password-form');

const NewPasswordForm: FC = () => {
  const authService: AuthService = new AuthService();
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const uid = searchParams.get('uid') as string;
  const token = searchParams.get('token') as string;
  const cabinet = searchParams.get('cabinet') as string;

  const { t } = useTranslation();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    authService
      .newPassword(uid, token, {
        new_password1: newPassword,
        new_password2: newPasswordRepeat,
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          navigate(`/new-password/success/${cabinet}`);
        }

        return res.json();
      })

      .then((data) => {
        if (data.new_password1) {
          setErrors((prevState) => {
            return {
              ...prevState,
              new_password1: data.new_password1,
            };
          });
        }

        if (data.new_password2) {
          setErrors((prevState) => {
            return {
              ...prevState,
              new_password2: data.new_password2,
            };
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <form className={newPasswordForm()} onSubmit={onSubmit}>
      <TextField
        value={newPassword}
        onChange={setNewPassword}
        label={t('set-new-password-form.new-password')}
        placeholder={t('set-new-password-form.new-password-placeholder')}
        mix={[newPasswordForm('text-field')]}
        name="email"
        error={(errors.new_password1 && errors.new_password1[0]) || ''}
      />
      <TextField
        value={newPasswordRepeat}
        onChange={setNewPasswordRepeat}
        label={t('set-new-password-form.repeat-new-password')}
        placeholder={t('set-new-password-form.repeat-new-password-placeholder')}
        mix={[newPasswordForm('text-field')]}
        name="password"
        htmlType="password"
        error={(errors.new_password2 && errors.new_password2[0]) || ''}
      />
      <Button formBtn type="submit">
        {t('set-new-password-form.btn-send')}
      </Button>
    </form>
  );
};

export default NewPasswordForm;
