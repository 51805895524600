export const ADWILE_DOCS_PAGE_LINK = 'https://docs.adwile.com';

export const RECOMMEND_TECH_RULES_LINK = `${ADWILE_DOCS_PAGE_LINK}/pravila_primeneniya_rekomendatelnykh_tekhnologiy.pdf`;

export const OFFER_LINK = `${ADWILE_DOCS_PAGE_LINK}/oferta_publisher.pdf`;

export const HELP_ADWILE_LINK = 'https://help.adwile.com';

export const HELP_ADWILE_FOR_PUBLISHERS_LINK = `${HELP_ADWILE_LINK}/паблишерам`;

export const HELP_ADWILE_FOR_ADVERTISERS_LINK = `${HELP_ADWILE_LINK}/рекламодателям`;

export const BLOG_ADWILE_LINK = 'https://blog.adwile.com';

export const BLOG_ADWILE_NEWS_LINK = `${BLOG_ADWILE_LINK}/category/news`;

export const PAYMENTS_POLICY_LINK = `${HELP_ADWILE_LINK}/sposoby-vyplat-i-periodichnost`;

export const SITES_MODERATION_POLICY_LINK = `${HELP_ADWILE_LINK}/moderatsiya-saytov`;

export const PREMIUM_ADWILE_LINK = 'https://premium.adwile.com/';

export const ADWILE_TECH_LINK = 'https://adwile.tech/';

export const TG_ADWILE_PUBLISHER_LINK = 'https://t.me/adwile_publisher';

export const TG_ADWILE_SUPPORT = 'https://t.me/adwile_support';

export const VK_ADWILE_LINK = 'https://vk.com/adwile';

export const SKOLKOVO_LINK = 'https://sk.ru';
