import { FC, ReactNode } from 'react';
import { NoStrictEntityMods } from '@bem-react/classname';
import { bemClassName } from '../../../utils/bemClassName';

interface IText {
  mods?: NoStrictEntityMods;
  mix?: string[];
  children?: ReactNode;
}

const text = bemClassName('text');

const Text: FC<IText> = ({ children, mods = {}, mix = [] }) => {
  return <p className={text(mods, mix)}>{children}</p>;
};

export default Text;
