/* eslint-disable no-use-before-define */
import { Language } from './language';
import Ru from './assets/ru.png';
import En from './assets/en.png';
import { useEffect } from 'react';
import * as Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;
  const scroll = Scroll.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
  }, [currentLanguage]);

  return (
    <Language
      currentLanguage={currentLanguage || 'ru'} // тут вопрос
      languages={LANGUAGES}
      onClick={(language: string) => {
        i18n.changeLanguage(language);
      }}
    />
  );
};

const LANGUAGES = [
  { language: 'en', name: 'English', flag: En },
  { language: 'ru', name: 'Русский', flag: Ru },
];
