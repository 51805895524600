import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './StageUniversalForms.module.scss';
import { Cabinet, signIn } from './StageApi';
import TextField from '../../atoms/TextField';
import { Button } from '../../atoms';

type AfterLoginAction = 'redirect' | 'token';

export const StageUniversalForm = (): JSX.Element => {
  const { cabinet: urlCabinet } = useParams<{ cabinet?: string }>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    cabinet: (urlCabinet as Cabinet) || Cabinet.Webmaster,
    afterLogin: 'redirect' as AfterLoginAction,
  });
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [token, setToken] = useState('');
  const [loginCabinetLink, setLoginCabinetLink] = useState('');
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [useLocalhost, setUseLocalhost] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setShowError(false);
    setErrors({});
    setToken('');
    setLoginCabinetLink('');
    try {
      const response = await signIn(formData.cabinet, {
        email: formData.email,
        password: formData.password,
      });

      const data = await response.json();
      const isExchangeCabinet = formData.cabinet === 'exchange';
      const subDomain = isExchangeCabinet ? '24smi.info' : 'adwile.com';
      const isDspSelected = formData.cabinet === 'dsp';
      const cabinet = isDspSelected ? 'dsp-partner' : formData.cabinet;

      const loginLink = useLocalhost
        ? `http://localhost:${PORT[cabinet]}/app/login?token=${data.token}`
        : `https://${cabinet}-stage.${subDomain}/app/login?token=${data.token}`;

      if (response.ok && response.status === 200) {
        if (formData.afterLogin === 'token') {
          setToken(data.token);
          setLoginCabinetLink(loginLink);
        }

        if (formData.afterLogin === 'redirect') {
          return (window.location.href = loginLink);
        }
      }

      // errors handling
      if (!response.ok && response.status === 401 && data) {
        if (data.email) {
          setErrors((prevState) => {
            return {
              ...prevState,
              email: data.email,
            };
          });
        }

        if (data.password) {
          setErrors((prevState) => {
            return {
              ...prevState,
              password: data.password,
            };
          });
        }
      }
    } catch (error) {
      console.warn(error);
      setLoginCabinetLink('');
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopyNotification(true);
      setTimeout(() => setShowCopyNotification(false), 3000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleCabinetChange = (newCabinet: Cabinet) => {
    setFormData({
      ...formData,
      cabinet: newCabinet,
    });
    navigate(`/stage/form/${newCabinet}`);
  };

  return (
    <div className={styles.wrapper}>
      {showCopyNotification && (
        <div className={styles.notification}>Значение скопировано</div>
      )}
      {showError && (
        <div className={`${styles.notification} ${styles.error}`}>
          Произошла ошибка
        </div>
      )}
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.formTitle}>Stage Customer Universal Form</h2>
        <h3>Выберите тип кабинета</h3>
        <div className={styles.radioGroup}>
          {Object.values(Cabinet).map((cabinet) => (
            <label key={cabinet}>
              <input
                type="radio"
                name="cabinet"
                value={cabinet}
                checked={formData.cabinet === cabinet}
                onChange={(e) => handleCabinetChange(e.target.value as Cabinet)}
              />
              {cabinet}
            </label>
          ))}
        </div>

        <h3>Выберите действие</h3>
        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="afterLogin"
              value="redirect"
              checked={formData.afterLogin === 'redirect'}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  afterLogin: e.target.value as AfterLoginAction,
                })
              }
            />
            Перейти в кабинет
          </label>
          <label>
            <input
              type="radio"
              name="afterLogin"
              value="token"
              checked={formData.afterLogin === 'token'}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  afterLogin: e.target.value as AfterLoginAction,
                })
              }
            />
            Получить токен
          </label>
        </div>
        <div className={styles.radioGroup}>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={useLocalhost}
              onChange={(e) => setUseLocalhost(e.target.checked)}
            />
            Использовать в URL адресе localhost
          </label>
        </div>

        <TextField
          htmlType="text"
          placeholder="Email"
          value={formData.email}
          onChange={(value) => setFormData({ ...formData, email: value })}
          label="Email"
          error={(errors.email && errors.email[0]) || ''}
        />
        <TextField
          htmlType="password"
          placeholder="Password"
          value={formData.password}
          onChange={(value) => setFormData({ ...formData, password: value })}
          label="Password"
          error={(errors.password && errors.password[0]) || ''}
        />

        {formData.afterLogin === 'token' && (
          <>
            <div className={styles.fieldWithButton}>
              <TextField
                tool={
                  <Button
                    disabled={!token}
                    size="tool"
                    type="button"
                    onClick={() => copyToClipboard(token)}
                  >
                    Копировать
                  </Button>
                }
                htmlType="text"
                placeholder="Token"
                value={token}
                onChange={setToken}
                label="Token"
              />
            </div>
            <div className={styles.fieldWithButton}>
              <TextField
                tool={
                  <Button
                    disabled={!loginCabinetLink}
                    size="tool"
                    type="button"
                    onClick={() => copyToClipboard(loginCabinetLink)}
                  >
                    Копировать
                  </Button>
                }
                rows={3}
                as="textarea"
                htmlType="text"
                placeholder="Ссылка для входа в кабинет"
                value={loginCabinetLink}
                onChange={setLoginCabinetLink}
                label="Ссылка для входа в кабинет"
              />
            </div>
          </>
        )}

        <Button formBtn type="submit" disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Login'}
        </Button>
      </form>
    </div>
  );
};

const PORT: Record<string, number> = {
  advert: 4200,
  'dsp-partner': 4201,
  exchange: 4202,
  webmaster: 4203,
};
