import { useTranslation } from 'react-i18next';
import useCabinet from '../../../hooks/useCabinet';
import Auth from '../../molecules/Auth';

import { useRoutes } from '../../../hooks/useRoutes';
import ResetForm from './ResetForm';
import Link from '../../atoms/Link';

export const ResetTemplate = () => {
  const { cabinet } = useCabinet();
  const { getLoginRoute } = useRoutes();
  const { t } = useTranslation();

  return (
    <Auth
      title={t('access-recovery-form.title')}
      navigate={
        <Link mix={['auth__link']} to={getLoginRoute(cabinet)}>
          {t('access-recovery-form.link-back-tp-auth')}
        </Link>
      }
    >
      <ResetForm />
    </Auth>
  );
};
