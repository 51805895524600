import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styles from './cookie-notification.module.scss';
import cs from 'classnames';
import { useRoutes } from '../../../../shared/lib/hooks/useRoutes';

export const CookieNotification = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { COOKIES_POLICY } = useRoutes();

  const [isNotVisible, setIsNotVisible] = useState(
    localStorage.getItem('cookies policy accepted') === 'true',
  );

  const handleClick = () => {
    localStorage.setItem('cookies policy accepted', 'true');
    setIsNotVisible(true);
  };

  if (isNotVisible) {
    return null;
  }

  return (
    <div className={cs(styles['wrapper'])}>
      <h2 className={cs(styles['title'])}>{t('cookies_notify.title')}</h2>

      <div className={cs(styles['description'])}>
        {t('cookies_notify.description_1')}
      </div>
      <div className={cs(styles['description'])}>
        <Trans
          i18nKey="cookies_notify.description_2"
          components={[
            <span></span>,
            <a
              className={cs(styles['link'])}
              href={COOKIES_POLICY.href}
              target="_blank"
              rel="noreferrer"
            ></a>,
          ]}
        />
      </div>
      <button
        onClick={handleClick}
        className={cs(styles['button'])}
        type="button"
      >
        {t('cookies_notify.button')}
      </button>
    </div>
  );
};
