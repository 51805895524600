import classnames from "classnames";
import { FC } from "react";

import S from "./BlockHeader.module.scss";
import { BlockHeaderProps } from "./types";

export const BlockHeader: FC<BlockHeaderProps> = ({ block, title, noTopMargin, whiteText }) => (
  <div className={classnames(S.BlockHeader, { [S.NoTopMargin]: noTopMargin })}>
    <h3 className={S.BlockTitle}>{block}</h3>
    <h2
      className={classnames(S.Title, {
        [S.WhiteColorText]: whiteText,
      })}
    >
      {title}
    </h2>
  </div>
);
