import { FC, FormEvent, useState } from 'react';
import { AuthService, User } from '../../../../services/auth.service';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../atoms';
import useCabinet from '../../../../hooks/useCabinet';
import { mapCabinetToDomain } from '../../../../utils/mapCabinetToDomain';
import TextField from '../../../atoms/TextField';
import { bemClassName } from '../../../../utils/bemClassName';
import Link from '../../../atoms/Link';
import { InlineMessage } from './inline-message';
import styles from './sign-in-form.module.scss';
import cs from 'classnames';

const signInForm = bemClassName('sign-in-form');

const SignInForm: FC = () => {
  const authService: AuthService = new AuthService();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const { t } = useTranslation();
  const [isUnknownError, setIsUnknownError] = useState(false);

  const { cabinet } = useCabinet();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    setIsUnknownError(false);

    const user: User = {
      email,
      password,
    };

    authService
      .signIn(cabinet, user)
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          const domain = mapCabinetToDomain[cabinet];

          return (window.location.href = `http://${domain}.adwile.com/app/login?token=${data.token}`);
        }

        if (data.email) {
          setErrors((prevState) => {
            return {
              ...prevState,
              email: data.email,
            };
          });
        }

        if (data.password) {
          setErrors((prevState) => {
            return {
              ...prevState,
              password: data.password,
            };
          });
        }
      })
      .catch((err) => {
        setIsUnknownError(true);
      });
  };

  return (
    <form className="" onSubmit={onSubmit}>
      {isUnknownError && (
        <InlineMessage
          appearance="error"
          className={cs(styles['inline-message'])}
        >
          Unknown error
        </InlineMessage>
      )}
      <TextField
        value={email}
        onChange={setEmail}
        label={t('auth-reg-form.email')}
        placeholder={t('auth-reg-form.email-placeholder')}
        mix={[signInForm('text-field')]}
        name="email"
        error={(errors.email && errors.email[0]) || ''}
      />
      <TextField
        value={password}
        onChange={setPassword}
        label={t('auth-reg-form.password')}
        placeholder={t('auth-reg-form.password-placeholder')}
        mix={[signInForm('text-field')]}
        name="password"
        htmlType="password"
        error={(errors.password && errors.password[0]) || ''}
      />
      <div className={signInForm('remember-me')}>
        {/* <Checkbox
          checked={rememberMe}
          setChecked={(value: boolean) => setRememberMe(value)}
          label={t('auth-reg-form.remember-me')}
        /> */}
        <Link to={`/reset/${cabinet}`} mix={[signInForm('link')]}>
          <div>{t('auth-reg-form.restore-access')}</div>
        </Link>
      </div>
      <Button formBtn type="submit">
        {t('auth-reg-form.btn-log-in')}
      </Button>
    </form>
  );
};

export default SignInForm;
