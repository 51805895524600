/* eslint-disable react/jsx-props-no-spreading */
import { Content, Header, Item, Root, Trigger } from "@radix-ui/react-accordion";
import React, { FC } from "react";

import { ReactComponent as CloseCircleIcon } from "../../../icons/common/close-circle.svg";
import S from "./Collapse.module.scss";
import { CollapseItemProps, CollapseProps } from "./types";

export const Collapse: FC<CollapseProps> = ({ children, ...props }) => <Root {...props}>{children}</Root>;

export const CollapseItem: FC<CollapseItemProps> = ({ header, value, children, triggerArrow }) => (
  <Item value={value} className={S.Item}>
    <Header>
      <Trigger className={S.Trigger}>
        {header}
        <div className={S.TriggerIcon}>{triggerArrow || <CloseCircleIcon />}</div>
      </Trigger>
    </Header>
    <Content className={S.Content}>{children}</Content>
  </Item>
);
